import React from "react";
import {
  CtaLink,
  CtaSpan,
  EnrollButton,
  FormSection,
  SecondaryCtaText,
} from "./LeadsForm.styles";
import { Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import dynamic from "next/dynamic";
const FormBuilder = dynamic(() => import('@/outscal-commons-frontend/FormBuilder/FormUI'), {
  ssr: false // Optional: Disable server-side rendering if needed
});;
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import SocialAuthIcons from "@/mvcComponents/Commons/SocialAuthIcons/SocialAuthIcons.controller";

const LeadsFormView = ({
  formName,
  formData,
  onSubmit,
  defaultValues,
  submitText,
  hasAuthToken,
}) => {
  return (
    <FormSection
      id="courses-leads-form"
      justifyContent="space-between"
      align="center"
      textAlign="cneter"
      gap="16px"
      padding="24px"
      hasAuthToken={hasAuthToken}
    >
      <Text fontSize="xxxl" fontWeight="midBold" align="center">
        Reach out to us
      </Text>
      <FormBuilder
        formName={formName}
        Inputs={formData}
        inputStyles={{ direction: "column" }}
        formStyles={{ gap: "24px" }}
        onSubmit={(e) => {
          onSubmit(e);
        }}
        defaultValues={defaultValues}
        SubmitButton={EnrollButton}
        submitText={submitText}
        CustomComponent={
          <Div margin="-8px 0">
            <SecondaryCtaText>
              <CtaSpan color="lightTextGrey">
                By clicking Submit, you agree to the Outscal{" "}
              </CtaSpan>
              <CtaLink
                hoverColor="themeBlue"
                color="themeBlue"
                href="/privacy-policy"
                target="_blank"
                data-analytics={[UIElements.LINK, "privacy_policy_opened"]}
              >
                Privacy Policy
              </CtaLink>
            </SecondaryCtaText>
          </Div>
        }
      />
      {!hasAuthToken && <SocialAuthIcons />}
    </FormSection>
  );
};

export default LeadsFormView;
