import Link from "next/link";
import { FaCircleCheck } from "react-icons/fa6";
import styled, { css } from "styled-components";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import { shine } from "@/outscal-commons-frontend/Styled/Animations";
import { ColDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { YellowButton } from "@/outscal-commons-frontend/Styled/Buttons";

export const CtaSpan = styled.span`
  font-size: ${(p) => p.theme.fontSize.sm};
  color: ${(p) => p.theme.colors[p.color ? p.color : "black"]};
  cursor: pointer;
  font-weight: ${(p) => p.theme.fontWeight.medium};
`;

export const CtaLink = styled(Link)`
  font-size: ${(p) => p.theme.fontSize.sm};
  color: ${(p) => p.theme.colors[p.color ? p.color : "black"]};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  &:hover {
    text-decoration: underline;
    color: ${(p) => p.theme.colors[p.hoverColor ? p.hoverColor : "black"]};
  }
`;

export const SecondaryCtaText = styled(Text)`
  text-align: center;
  font-size: ${(p) => p.theme.fontSize.md};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};

  @media (max-width: 600px) {
    color: ${(p) => p.theme.colors.textGrey};
    font-size: ${(p) => p.theme.fontSize.sm};
    font-weight: ${(p) => p.theme.fontWeight.regular};
  }
`;

export const CheckIcon = styled(FaCircleCheck)`
  margin: auto;
  font-size: ${(p) => p.theme.fontSize["5xl"]};
  color: ${(p) => p.theme.colors.textGreen};
`;

export const FormSection = styled(ColDiv)`
  flex: none;
  margin: ${(p) => (p.hasAuthToken ? "auto" : "0")};
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @media screen and (min-width: 768px) {
    width: 33%;
  }
`;

export const EnrollButton = styled(YellowButton)`
  margin: auto;
  font-size: 24px;
  border-radius: 8px;
  align-self: center;
  font-size: 16px;
  padding: 12px 24px;
  ${(p) =>
    p.animate &&
    css`
      animation: ${shine()} 800ms infinite;
    `}
`;
