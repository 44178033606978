import React from "react";
import {
  CheckIcon,
  EnrollButton,
  FormSection,
} from "./LeadsForm.styles";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import Link from "next/link";

const FormFilledView = ({
  heading = "",
  subHeading = "",
  ctaText = "",
  ctaLink = "#",
}) => {
  return (
    <FormSection
      id="courses-leads-form-filled"
      justifyContent="space-between"
      align="center"
      textAlign="center"
      gap="16px"
      padding="24px"
    >
      <CheckIcon />
      <Text fontSize="xxxl" fontWeight="midBold" align="center">
        {heading}
      </Text>
      <Text fontSize="md" fontWeight="normal" align="center" color="textGrey">
        {subHeading}
      </Text>
      <Link href={ctaLink}>
        <EnrollButton data-analytics={[UIElements.BUTTON, "check-our-courses"]} animate={true}>
          {ctaText}
        </EnrollButton>
      </Link>
    </FormSection>
  );
};

export default FormFilledView;
