import React, { useState, useEffect } from "react";
import NetworkManager from "@/outscal-commons-frontend/Managers/NetworkManager";
import useUtms from "@/hooks/useUtms";
import { toast } from "react-toastify";
import {
  CoursesLeadsFormData as coursesLeadsFormData,
  defaultPhoneNumberData,
  formFilledData,
  fsgdCourseDetails,
  redirectToCourseDetailsWithResumePopup,
} from "./LeadsForm.model";
import LeadsFormView from "./LeadsForm.view";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import FormFilledView from "./FormFilledView";
import { useRouter } from "next/router";
import useAuthActions from "@/store/actions/authAction";

const LeadsForm = ({ paidCoursesIdAndLabels = [] }) => {
  const router = useRouter();
  const {
    GetUtms,
    GetLandingIntent,
    GetIntentFromRouter,
    GetRetargetingIntent,
  } = useUtms();
  const { user, getAndSetUserProfile, updateUserProfile } = useUserActions();
  const [defaultValues, setDefaultValues] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const { auth, setToken } = useAuthActions();

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      setSubmitted(localStorage.getItem("courseLeadSubmitted") === "true");
    }
  }, []);

  const populateFormData = async () => {
    coursesLeadsFormData.inputs.course_of_interest.optionsList =
      paidCoursesIdAndLabels;

    let defaultObj = {};
    defaultObj.name = user?.userJobProfile?.name;
    defaultObj.email = user?.userDetails?.email;
    defaultObj.course_of_interest = fsgdCourseDetails;
    defaultObj.phone_number = defaultPhoneNumberData;
    defaultObj.phone_number.number = user?.userJobProfile?.phone_number;
    setDefaultValues(defaultObj);
  };

  useEffect(() => {
    populateFormData();
  }, [user]);

  const onSubmit = async (e) => {
    let userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const landingIntent = GetLandingIntent();
    const intent = GetIntentFromRouter(router);
    const retargetingIntent = GetRetargetingIntent();

    const resp = await NetworkManager.post(
      "course-leads/create",
      {
        name: e.name,
        email: e.email,
        phone_number: e.phone_number,
        intent: { landingIntent, intent, retargetingIntent },
        course_of_interest: e.course_of_interest.id,
        utms: GetUtms(),
        authDetails: {
          email: userData?.email,
          name: userData?.name,
          userId: userData?._id,
        },
      },
      undefined,
      undefined,
      true
    );
    const hasToken = resp?.data?.user?.token;
    if (resp.error) {
      toast.error(resp.message);
    } else {
      toast.success("We will contact you soon.");
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("courseLeadSubmitted", true);
      }
      setSubmitted(true);
      if (!auth?.token) {
        if (hasToken) {
          setToken(hasToken, resp?.data?.user);
          await getAndSetUserProfile();
        }
      } else {
        if (!user?.userJobProfile?.phone_number?.length) {
          await updateUserProfile();
        }
      }
      router.push(
        `/course/${e.course_of_interest.id}${
          hasToken ? redirectToCourseDetailsWithResumePopup : ""
        }`
      );
    }
  };

  return !submitted ? (
    <LeadsFormView
      formName={coursesLeadsFormData?.name}
      formData={coursesLeadsFormData?.inputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitText={auth?.token ? "Connect With Us" : "Register and Enroll now"}
      hasAuthToken={auth?.token}
      paidCoursesIdAndLabels={paidCoursesIdAndLabels}
    />
  ) : (
    <FormFilledView
      heading={formFilledData?.heading}
      subHeading={formFilledData?.subHeading}
      ctaText={formFilledData?.ctaText}
      ctaLink={formFilledData?.ctaLink}
    />
  );
};

export default LeadsForm;
