import * as Yup from "yup";
import {
  PHONE_NUMBER,
  TEXT,
  SELECT,
} from "@/outscal-commons-frontend/FormBuilder/InputTypes";
import { isValidNumber } from "@/outscal-commons-frontend/utils/validations";
import { EmailValidationRegex } from "@/outscal-commons-frontend/Forms/validation";

export const fsgdCourseDetails = {
  id: "full-stack-game-development",
  label: "Full-Stack Game Development ",
};
export const redirectToCourseDetailsWithResumePopup =
  "?clickFrom=course-leads-form";

export const defaultPhoneNumberData = {
  number: "",
  country_code: "IN",
  calling_code: "+91",
};

export const formFilledData = {
  heading: "Thank you for filling the form.",
  subHeading: "Our team will contact you soon.",
  ctaText: "Check out our courses",
  ctaLink: "/courses#Courses",
};

export const CoursesLeadsFormData = {
  name: "CoursesLeadsForm",
  inputs: {
    name: {
      type: "text",
      defaultValue: "",
      placeholder: "Name*",
      validation: Yup.string().required(),
      inputType: TEXT,
    },
    email: {
      type: "email",
      defaultValue: "",
      placeholder: "Email*",
      validation: Yup.string()
        .matches(EmailValidationRegex, "Invalid email address")
        .email("Invalid email address")
        .required("Email is required"),
      inputType: TEXT,
    },
    phone_number: {
      defaultValue: "",
      placeholder: "Phone number",
      validation: Yup.object().test(
        "valid-phone-number",
        "Please enter a valid phone number",
        isValidNumber
      ),
      inputType: PHONE_NUMBER,
    },
    course_of_interest: {
      heading: "Course you are interested in*",
      inputType: SELECT,
      optionsList: [],
      validation: Yup.object().required(),
    },
  },
};
